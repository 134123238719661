import { DatatableHeaderData } from "@components/ui";
import { ALL_YEARS } from "@constants/all-years";
import { useRouterQuery } from "@hooks/use-router-query";
import { MeasureType } from "@icf-corp/hivgov-common-ui/line-chart/models";
import { HomeQueryParams } from "@models/home-query-params";
import { STRATIFICATION_MAP } from "@constants/stratification-map";
import { useDataFilterStore } from "@stores/use-data-filter-store";
import { getDataLabel } from "functions/chart";
import {
  getCaseCountsDataByYear,
  getCaseCountsGoalsByYear,
  getGoalsFromData,
  getValuesFromData,
  selectYears,
  sortCaseCountsStrats,
  getCIByMeasure,
} from "functions/data";
import { selectTableYearHeaders } from "functions/table";
import { useTranslation } from "next-i18next";
import { useHomeDataColors } from "../use-home-data-colors";
import { useHomePageCaseCounts } from "../use-home-page-case-counts";
import { useHomePageDataYears } from "../use-home-page-data-years";

export const useHomePageTable = () => {
  const { t } = useTranslation(["data"]);

  const {
    query: { measure },
  } = useRouterQuery<HomeQueryParams>();

  const activeMeasure = (
    measure === "rate" ? "percentage" : "count"
  ) as MeasureType;
  const [activeSortKey, activeConfidenceIntervals] = useDataFilterStore(
    (state) => [state.activeSortKey, state.activeConfidenceIntervals]
  );
  const sortAttr: string | undefined =
    STRATIFICATION_MAP[activeSortKey.toLocaleLowerCase()];

  // Lets get header data from disease years endpoint
  const { data: yearHeaders } = useHomePageDataYears({
    select: selectTableYearHeaders,
  });

  // The actual table headers with placeholder years
  const headers: DatatableHeaderData[] = [
    { id: "label", value: "Label" },
    ...(yearHeaders ??
      ALL_YEARS.map((year, i) => ({
        id: i,
        value: year === "2020" ? "2020*" : year,
      }))),
    { id: "goal-2025", value: "2025 Goal" },
    { id: "goal-2030", value: "2030 Goal" },
  ];

  // The rows modified for table format
  const { data: years } = useHomePageDataYears({
    select: selectYears,
  });
  const { data: rows, isLoading: isRowsLoading } = useHomePageCaseCounts({
    enabled: !!years,
    select: (rawData) =>
      sortCaseCountsStrats(rawData, sortAttr).reduce(
        (acc: (number | string | undefined | null)[][], { strat, i }) => {
          const dataByYear = getCaseCountsDataByYear(
            rawData.data[i].data,
            years ?? []
          );
          const goalsByYear = getCaseCountsGoalsByYear(rawData.data[i].goals);

          if (activeConfidenceIntervals.includes(i)) {
            acc.push([
              `${getDataLabel(strat)}\n(${t("data:data-table.estimate")})`,
              ...getValuesFromData(dataByYear, activeMeasure),
              ...getGoalsFromData(goalsByYear, activeMeasure),
            ]);
            acc.push([
              `${getDataLabel(strat)}\n(${t("data:data-table.lower-ci")})`,
              ...getCIByMeasure(dataByYear, "lower", activeMeasure),
              undefined,
              undefined,
            ]);
            acc.push([
              `${getDataLabel(strat)}\n(${t("data:data-table.upper-ci")})`,
              ...getCIByMeasure(dataByYear, "upper", activeMeasure),
              undefined,
              undefined,
            ]);
          } else {
            acc.push([
              getDataLabel(strat),
              ...getValuesFromData(dataByYear, activeMeasure),
              ...getGoalsFromData(goalsByYear, activeMeasure),
            ]);
          }
          return acc;
        },
        []
      ),
  });

  // Hook into page wide colors
  const { data: colors, isLoading: isColorsLoading } = useHomeDataColors();

  const isTableDataLoading = isColorsLoading || isRowsLoading;

  return {
    headers,
    rows,
    colors,
    isTableDataLoading,
  };
};
