import { DataSources as DataSourceForID } from "@components/pages/geographic/data-sources";
import { IndicatorBadge } from "@components/ui/indicator-badge";
import { Diseases } from "@enums/diseases";
import { Title } from "@mantine/core";
import { useRouterQuery } from "@hooks";
import { HomeQueryParams } from "@models";
import { useTranslation } from "next-i18next";
import classes from "./data-sources.module.css";

export const DataSources = () => {
  const { t } = useTranslation(["data-sources", "common"]);
  const {
    query: { indicator },
  } = useRouterQuery<HomeQueryParams>();
  const id = (indicator ?? Diseases.Incidence) as number;
  const key = Diseases[id];
  return (
    <>
      <IndicatorBadge id={id}>
        <Title order={3}>{t(`common:indicators.${key}.display-name`)}</Title>
      </IndicatorBadge>
      <Title order={3} mt="xl" className={classes.displayInline}>
        {` ${t("common:data-sources")}`}
      </Title>
      <DataSourceForID id={id} />
    </>
  );
};
