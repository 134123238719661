import { CaseCounts } from "@api/case-counts";
import { STRATIFICATION_MAP } from "@constants/stratification-map";
import { useDataFilterStore } from "@stores/use-data-filter-store";
import { selectLabelsFromCaseCounts } from "functions/data";
import { useCallback } from "react";
import { useHomePageCaseCounts } from "../use-home-page-case-counts";

export const useHomeDataLabels = () => {
  // Get the sort key from store
  const activeSortKey = useDataFilterStore((state) => state.activeSortKey);
  const sortAttr: string | undefined =
    STRATIFICATION_MAP[activeSortKey.toLocaleLowerCase()];

  // Return query with selected colors
  return useHomePageCaseCounts({
    select: useCallback(
      (rawData: CaseCounts) => selectLabelsFromCaseCounts(rawData, sortAttr),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [sortAttr]
    ),
  });
};
