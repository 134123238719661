import { Ref, useEffect, useRef } from "react";

const DEFAULT_EVENTS = ["mousedown", "touchstart"];

export const useHomePageFiltersClickOutside = <T extends HTMLElement = any>(
  handler: () => void,
  events?: string[] | null,
  nodes?: (HTMLElement | null)[]
) => {
  const ref = useRef<T>();

  useEffect(() => {
    const listener = (event: any) => {
      const { target } = event ?? {};
      const isSelectItem =
        target?.matches(
          `.mantine-Combobox-option,
          [data-icon='caret-down'],
          .ant-select-tree-switcher,
          .ant-select-dropdown,
          .ant-select-tree-title,
          .ant-select-tree-checkbox-inner,
          .ant-select-tree-node-content-wrapper,
          .ant-select-tree-treenode,
          .ant-select-tree-indent,
          [data-tour-id='filters-button'],
          .tabler-icon-adjustments-horizontal,
          path`
        ) ?? false;

      if (isSelectItem) {
        return;
      }

      if (Array.isArray(nodes)) {
        const shouldIgnore =
          target?.hasAttribute("data-ignore-outside-clicks") ||
          (!document.body.contains(target) && target.tagName !== "HTML");
        const shouldTrigger = nodes.every(
          (node) => !!node && !event.composedPath().includes(node)
        );
        // eslint-disable-next-line no-unused-expressions
        shouldTrigger && !shouldIgnore && handler();
      } else if (ref.current && !ref.current.contains(target)) {
        handler();
      }
    };

    (events ?? DEFAULT_EVENTS).forEach((fn) =>
      document.addEventListener(fn, listener)
    );

    return () => {
      (events ?? DEFAULT_EVENTS).forEach((fn) =>
        document.removeEventListener(fn, listener)
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, handler, nodes]);

  return ref as Ref<T>;
};
