import { Link } from "@components/ui";
import { useRouterQuery } from "@hooks";
import { useHomePageFiltersClickOutside } from "@hooks/home-page";
import {
  Box,
  Button,
  Divider,
  Flex,
  Loader,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { HomeQueryParams } from "@models";
import {
  DemographicFilter,
  useDataFilterStore,
  useDemographicTourStore,
} from "@stores";

import {
  Filters,
  useAgeGroups,
  useAreas,
  useGenders,
  useRaces,
  useSexes,
  useTransmissions,
} from "api";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useForm,
} from "react-hook-form";
import { Drawer } from "../drawer";
import { AreaMultiSelect } from "./area-multi-select";
import { FiltersMultiSelect } from "./filters-multi-select";
import classes from "./filters-drawer.module.css";

const DEFAULT_FILTERS: Filters = {
  ageGroupId: [],
  raceId: [],
  sexId: [],
  transmissionId: [],
  genderId: [],
};

type GenderOrSex = "sexId" | "genderId" | null;

export const FiltersDrawer = () => {
  const { t } = useTranslation(["data"]);
  const [isNational, { close, open }] = useDisclosure(true);
  const [genderOrSex, setGenderOrSex] = useState({
    stratified: {
      fieldName: null as GenderOrSex,
    },
    custom: {
      fieldName: null as GenderOrSex,
    },
  });

  const { query, replaceQuery } = useRouterQuery<HomeQueryParams>();
  const { isReady } = useRouter();

  const {
    control: stratifiedControl,
    handleSubmit: stratifiedHandleSubmit,
    reset: stratifiedReset,
  } = useForm<Filters>();
  const {
    control: customControl,
    handleSubmit: customHandleSubmit,
    reset: customReset,
  } = useForm<Filters>({ defaultValues: DEFAULT_FILTERS });

  const [
    activeDemographicFilter,
    activeMenuPanel,
    clearCustomCaseCounts,
    isTouched,
    showError,
    setActiveDemographicFilter,
    setActiveMenuPanel,
    setCustomFilters,
    setIsTouched,
    setShowError,
  ] = useDataFilterStore((state) => [
    state.activeDemographicFilter,
    state.activeMenuPanel,
    state.clearCustomCaseCounts,
    state.isTouched,
    state.showError,
    state.setActiveDemographicFilter,
    state.setActiveMenuPanel,
    state.setCustomFilters,
    state.setIsTouched,
    state.setShowError,
  ]);
  const [isRunning, stepIndex, setStepIndex] = useDemographicTourStore(
    (state) => [state.isRunning, state.stepIndex, state.setStepIndex]
  );

  const { isError: isAreasError, isLoading: isAreasLoading } = useAreas();
  const {
    data: ageGroups,
    isError: isAgeGroupsError,
    isLoading: isAgeGroupsLoading,
  } = useAgeGroups();
  const {
    data: genders,
    isError: isGendersError,
    isLoading: isGendersLoading,
  } = useGenders();
  const {
    data: races,
    isError: isRacesError,
    isLoading: isRacesLoading,
  } = useRaces();
  const {
    data: sexes,
    isError: isSexesError,
    isLoading: isSexesLoading,
  } = useSexes();
  const {
    data: transmissions,
    isError: isTransmissionsError,
    isLoading: isTransmissionsLoading,
  } = useTransmissions();

  const isRetrievingData =
    isAgeGroupsError ||
    isAgeGroupsLoading ||
    isAreasError ||
    isAreasLoading ||
    isGendersError ||
    isGendersLoading ||
    isRacesError ||
    isRacesLoading ||
    isSexesError ||
    isSexesLoading ||
    isTransmissionsError ||
    isTransmissionsLoading;

  const isStratifiedFilter = activeDemographicFilter === "stratified";

  useEffect(() => {
    const getDefaultValue = (value: string[] | string | undefined) =>
      // eslint-disable-next-line no-nested-ternary
      Array.isArray(value) ? value : value === undefined ? [] : [value];

    if (isReady) {
      // eslint-disable-next-line no-unused-expressions
      query.location ? close() : open();
      stratifiedReset({
        ageGroupId: getDefaultValue(query.age),
        raceId: getDefaultValue(query.race),
        sexId: getDefaultValue(query.sex),
        transmissionId: getDefaultValue(query.transmission),
        genderId: getDefaultValue(query.gender),
      });
      if (query.sex) {
        setGenderOrSex((prevState) => ({
          ...prevState,
          [activeDemographicFilter]: { fieldName: "sexId" },
        }));
      }
      if (query.gender) {
        setGenderOrSex((prevState) => ({
          ...prevState,
          [activeDemographicFilter]: { fieldName: "genderId" },
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  const handleNationalClick = () => {
    delete query.location;
    replaceQuery({ ...query });
    open();
  };

  const handleReset = () => {
    setShowError(false);
    setIsTouched(false);
    setActiveMenuPanel("");

    if (isStratifiedFilter) {
      stratifiedReset(DEFAULT_FILTERS);
      setGenderOrSex((prevState) => ({
        ...prevState,
        stratified: { fieldName: null },
      }));
      delete query.age;
      delete query.race;
      delete query.sex;
      delete query.transmission;
      delete query.gender;
      replaceQuery({ ...query });

      return;
    }

    setGenderOrSex((prevState) => ({
      ...prevState,
      custom: { fieldName: null },
    }));
    customReset();
    clearCustomCaseCounts();
  };

  const handleStratifiedSubmit = async ({
    ageGroupId,
    genderId,
    raceId,
    sexId,
    transmissionId,
  }: Partial<Filters>) => {
    if (isRunning) {
      setStepIndex(stepIndex + 1);
    }

    await replaceQuery({
      ...(ageGroupId && { age: ageGroupId }),
      ...(genderId && { gender: genderId }),
      ...(raceId && { race: raceId }),
      ...(sexId && { sex: sexId }),
      ...(transmissionId && { transmission: transmissionId }),
    });

    setShowError(false);
    setIsTouched(false);
    setActiveMenuPanel("");
  };

  const handleCustomSubmit = (data: Filters) => {
    customReset();
    setCustomFilters({
      ...data,
      areaId: query.location,
      diseaseId: [query.indicator!],
    });

    setShowError(false);
    setIsTouched(false);
    setGenderOrSex((prevState) => ({
      ...prevState,
      custom: { fieldName: null },
    }));
    setActiveMenuPanel("");
  };

  const ref = useHomePageFiltersClickOutside<HTMLDivElement>(() => {
    if (isRunning) {
      return;
    }

    if (isTouched) {
      setShowError(true);
      return;
    }

    setActiveMenuPanel("");
  });

  const getFormControl = () =>
    isStratifiedFilter ? stratifiedControl : customControl;

  const maxSelectedValues = isStratifiedFilter ? Infinity : 1;

  const handleGenderOrSexChange = (
    field: ControllerRenderProps<Filters, any>
  ) => {
    if (field.name === "sexId" || field.name === "genderId") {
      if (field.value?.length && field.value[0]) {
        setGenderOrSex((prevState) => ({
          ...prevState,
          [activeDemographicFilter]: { fieldName: field.name },
        }));
      } else if (
        genderOrSex[activeDemographicFilter].fieldName === field.name
      ) {
        setGenderOrSex((prevState) => ({
          ...prevState,
          [activeDemographicFilter]: { fieldName: null },
        }));
      }
    }
  };

  const handleDropdownClose = (
    field: ControllerRenderProps<Filters, any>,
    fieldState: ControllerFieldState
  ) => {
    if (fieldState.isDirty && !isTouched) {
      setIsTouched(true);
    }
    handleGenderOrSexChange(field);
  };

  const handleChange = (
    e: string[],
    field: ControllerRenderProps<Filters, any>
  ) => {
    if (e.length < (field.value?.length ?? 0)) {
      handleGenderOrSexChange({ ...field, value: e });
      setIsTouched(true);
    }
    field.onChange(e);
  };

  const handleFilterChange = (filter: DemographicFilter) => {
    setActiveDemographicFilter(filter);

    if (filter === "stratified") {
      clearCustomCaseCounts();
    }
  };

  return (
    <Drawer mounted={activeMenuPanel === "filters"} ref={ref}>
      <Drawer.Panel>
        <Box data-tour-id="locations" h="100%">
          <Title order={3}>{t("filters.titles.locations")}</Title>
          <Divider my="md" />
          <Button
            variant={isNational ? "filled" : "outline"}
            fullWidth
            onClick={handleNationalClick}
          >
            {t("filters.buttons.national")}
          </Button>
          <Button
            variant={!isNational ? "filled" : "outline"}
            onClick={close}
            fullWidth
            my="md"
          >
            {t("filters.buttons.state-and-county")}
          </Button>
          {!isNational && (
            <>
              <Text size="sm">
                {t("filters.descriptions.state-and-county")}
              </Text>
              {isRetrievingData ? (
                <Flex justify="center">
                  <Loader />
                </Flex>
              ) : (
                <AreaMultiSelect />
              )}
            </>
          )}
          <Text size="sm" pt="sm">
            {t("filters.descriptions.state-disclaimer.p")}
            <Link href="/data/geographic/indicators/">
              {t("filters.descriptions.state-disclaimer.a")}
            </Link>
            .
          </Text>
        </Box>
      </Drawer.Panel>
      <Drawer.Panel>
        <Title order={3}>{t("filters.titles.filters")}</Title>
        <Button.Group mt="md">
          <Button
            variant={isStratifiedFilter ? "filled" : "outline"}
            onClick={() => handleFilterChange("stratified")}
            size="xs"
            fullWidth
          >
            {t("filters.buttons.stratified")}
          </Button>
          <Button
            variant={isStratifiedFilter ? "outline" : "filled"}
            onClick={() => handleFilterChange("custom")}
            size="xs"
            fullWidth
          >
            {t("filters.buttons.custom")}
          </Button>
        </Button.Group>
        <Divider my="md" />
        {!isStratifiedFilter && (
          <Text size="sm">{t("filters.descriptions.custom")}</Text>
        )}
        {isRetrievingData ? (
          <Flex justify="center">
            <Loader />
          </Flex>
        ) : (
          <form
            onSubmit={
              activeDemographicFilter === "stratified"
                ? stratifiedHandleSubmit((data) => handleStratifiedSubmit(data))
                : customHandleSubmit((data) => handleCustomSubmit(data))
            }
          >
            <Stack gap="xs" data-tour-id="filters">
              <Controller
                name="ageGroupId"
                control={getFormControl()}
                render={({ field, fieldState }) => (
                  <FiltersMultiSelect
                    onBlur={field.onBlur}
                    value={field.value ?? []}
                    onChange={(e) => handleChange(e, field)}
                    onDropdownClose={() =>
                      handleDropdownClose(field, fieldState)
                    }
                    options={ageGroups!.map((ageGroup) => ({
                      value: ageGroup.id.toString(),
                      label: ageGroup.displayName,
                    }))}
                    label={t("filters.fields.age.label") as string}
                    placeholder={t("filters.fields.age.placeholder") as string}
                    enableSelectAll={isStratifiedFilter}
                    maxValues={maxSelectedValues}
                  />
                )}
              />
              <Controller
                name="raceId"
                control={getFormControl()}
                render={({ field, fieldState }) => (
                  <FiltersMultiSelect
                    onBlur={field.onBlur}
                    value={field.value ?? []}
                    onChange={(e) => handleChange(e, field)}
                    options={races!.map((race) => ({
                      value: race.id.toString(),
                      label: race.displayName,
                    }))}
                    label={t("filters.fields.race.label") as string}
                    placeholder={t("filters.fields.race.placeholder") as string}
                    enableSelectAll={isStratifiedFilter}
                    maxValues={maxSelectedValues}
                    onDropdownClose={() =>
                      handleDropdownClose(field, fieldState)
                    }
                  />
                )}
              />
              <Controller
                name="sexId"
                control={getFormControl()}
                render={({ field, fieldState }) => (
                  <FiltersMultiSelect
                    onBlur={field.onBlur}
                    value={field.value ?? []}
                    onChange={(e) => handleChange(e, field)}
                    options={sexes!.map((sex) => ({
                      value: sex.id.toString(),
                      label: sex.displayName,
                    }))}
                    label={t("filters.fields.sex.label") as string}
                    placeholder={t("filters.fields.sex.placeholder") as string}
                    enableSelectAll={isStratifiedFilter}
                    maxValues={maxSelectedValues}
                    disabled={
                      genderOrSex[activeDemographicFilter].fieldName ===
                      "genderId"
                    }
                    onDropdownClose={() =>
                      handleDropdownClose(field, fieldState)
                    }
                  />
                )}
              />
              <Controller
                name="transmissionId"
                control={getFormControl()}
                render={({ field, fieldState }) => (
                  <FiltersMultiSelect
                    onBlur={field.onBlur}
                    value={field.value ?? []}
                    onChange={(e) => handleChange(e, field)}
                    options={transmissions!.map((transmission) => ({
                      value: transmission.id.toString(),
                      label: transmission.displayName,
                    }))}
                    label={t("filters.fields.transmission.label") as string}
                    placeholder={
                      t("filters.fields.transmission.placeholder") as string
                    }
                    enableSelectAll={isStratifiedFilter}
                    maxValues={maxSelectedValues}
                    onDropdownClose={() =>
                      handleDropdownClose(field, fieldState)
                    }
                  />
                )}
              />
              {isNational && (
                <Controller
                  name="genderId"
                  control={getFormControl()}
                  render={({ field, fieldState }) => (
                    <FiltersMultiSelect
                      onBlur={field.onBlur}
                      value={field.value ?? []}
                      onChange={(e) => handleChange(e, field)}
                      options={genders!.map((gender) => ({
                        value: gender.id.toString(),
                        label: gender.displayName,
                      }))}
                      label={t("filters.fields.gender.label") as string}
                      placeholder={
                        t("filters.fields.gender.placeholder") as string
                      }
                      enableSelectAll={isStratifiedFilter}
                      maxValues={maxSelectedValues}
                      disabled={
                        genderOrSex[activeDemographicFilter].fieldName ===
                        "sexId"
                      }
                      onDropdownClose={() =>
                        handleDropdownClose(field, fieldState)
                      }
                    />
                  )}
                />
              )}
            </Stack>
            {showError && (
              <Text size="sm" mt="sm" className={classes.error}>
                {t("filters.errors.unsaved")}
              </Text>
            )}
            <Flex justify="space-between" mt="xl">
              <Button variant="outline" onClick={handleReset}>
                {t("filters.buttons.clear-filters")}
              </Button>
              <Button type="submit" data-tour-id="apply-filters">
                {t("filters.buttons.apply-filters")}
              </Button>
            </Flex>
          </form>
        )}
      </Drawer.Panel>
    </Drawer>
  );
};
