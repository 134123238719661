import { ColorSchemeImage, Hero as CustomHero } from "@components/ui";
import {
  Divider,
  Flex,
  Grid,
  Text,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";

import eheLogoDark from "@public/images/ehe-logo-dark.svg";
import eheLogoLight from "@public/images/ehe-logo-light.svg";

import hivLogoDark from "@public/images/hiv-gov-logo-dark.svg";
import hivLogoLight from "@public/images/hiv-gov-logo-light.svg";
import { getFromColorScheme } from "@utils/get-from-color-scheme";
import { useTranslation } from "next-i18next";
import darkSrc from "@public/images/data-hero-bg-dark.png";
import lightSrc from "@public/images/hero-intro-bg-data-lightmode.webp";

export const Hero = () => {
  const colorScheme = useComputedColorScheme("light");
  const { colors } = useMantineTheme();
  const { t } = useTranslation(["data", "common"]);

  return (
    <CustomHero src={{ dark: darkSrc, light: lightSrc }} alt="data page hero">
      <CustomHero.Title>{t("hero.title")}</CustomHero.Title>
      <CustomHero.Body>
        {/* //* This sort of thing can be turned into a Translation component */}
        <Text>
          {t("hero.description.ahead-is")}{" "}
          <Text
            span
            c={getFromColorScheme(
              colorScheme,
              colors.teal[8],
              colors.yellow[4]
            )}
            fw="bold"
          >
            {t("hero.description.one-tool")}
          </Text>{" "}
          {t("hero.description.rest")}
        </Text>
        <Divider
          my="md"
          color={getFromColorScheme(
            colorScheme,
            colors.teal[8],
            colors.yellow[4]
          )}
        />
        <Grid gutter="xl">
          <Grid.Col span={{ md: 6 }}>
            <Flex gap="xl">
              <ColorSchemeImage
                lightSrc={eheLogoLight}
                darkSrc={eheLogoDark}
                alt="Ending the HIV Epidemic"
                width={70}
                height={70}
                unoptimized
              />
              <Text fs="italic" fz="sm">
                <Text span fw="bold">
                  {t("hero.footnote.ending-the-hiv-epidemic")}
                </Text>{" "}
                {t("hero.footnote.ehe-rest")}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={{ md: 6 }}>
            <Flex gap="xl">
              <ColorSchemeImage
                lightSrc={hivLogoLight}
                darkSrc={hivLogoDark}
                alt="HIV.gov"
                width={70}
                height={70}
                unoptimized
              />
              <Text fs="italic" fz="sm">
                <Text span fw="bold">
                  {t("hero.footnote.hiv")}
                </Text>{" "}
                {t("hero.footnote.hiv-rest")}
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
      </CustomHero.Body>
    </CustomHero>
  );
};
