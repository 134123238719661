import { IndicatorCard } from "@components/pages/data/indicator-card";
import { useIndicatorData } from "@hooks/use-indicator-data";
import { Box, Flex, Grid, Loader, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useUiStore } from "@stores/use-ui-store";
import { useTranslation } from "next-i18next";
import React from "react";
import classes from "./indicator-detail.module.css";

export const IndicatorDetail = () => {
  const { t } = useTranslation(["data", "common"]);

  const activeIndicator = useUiStore((state) => state.activeIndicatorDetail);
  const setActiveIndicator = useUiStore(
    (state) => state.setActiveIndicatorDetail
  );

  const { indicatorData: indicators, isLoading: isIndicatorDataLoading } =
    useIndicatorData();

  const isVerySmallScreen = useMediaQuery(`(max-width: 399px)`);

  const selectedIndicator = indicators.find(
    (indicator) => indicator.id === activeIndicator
  );

  return (
    <Box my="xl">
      {isIndicatorDataLoading ? (
        <Flex justify="center">
          <Loader />
        </Flex>
      ) : (
        <>
          <Title order={2} mb="xs">
            {t("data:indicator-detail.about")}
          </Title>
          <Text mb="xl">{t("data:indicator-detail.description")}</Text>
          <Box
            className={classes.container}
            px={{ base: "md", xs: "xl", md: "2.5rem" }}
            py="xl"
          >
            <Text ta="center" mb="lg">
              {t("data:indicator-detail.instruction")}
            </Text>
            <Grid gutter={{ base: "sm", xs: "md", xl: "lg" }}>
              <Grid.Col
                className={classes.largerThanLg}
                mb={{ base: "xs", xs: "0" }}
              >
                {selectedIndicator && (
                  <IndicatorCard
                    {...selectedIndicator}
                    expanded
                    onSelect={() => setActiveIndicator(selectedIndicator.id)}
                  />
                )}
              </Grid.Col>
              {indicators.map((indicator) => (
                <React.Fragment key={indicator.id}>
                  <Grid.Col
                    span={{ base: isVerySmallScreen ? 6 : 4, sm: 2 }}
                    className={classes.largerThanLg}
                  >
                    <IndicatorCard
                      {...indicator}
                      faded={indicator.id === activeIndicator}
                      onSelect={() => setActiveIndicator(indicator.id)}
                    />
                  </Grid.Col>
                  <Grid.Col
                    span={{
                      md: indicator.id === activeIndicator ? 4.5 : 1.5,
                      xl: indicator.id === activeIndicator ? 5 : 1.4,
                    }}
                    className={classes.smallerThanLg}
                  >
                    <IndicatorCard
                      {...indicator}
                      expanded={indicator.id === activeIndicator}
                      onSelect={() => setActiveIndicator(indicator.id)}
                    />
                  </Grid.Col>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};
