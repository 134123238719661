import { Diseases } from "@enums/diseases";
import {
  selectQuarterDate,
  selectShowQuarterly,
  selectAnnualPrelimYear,
} from "@functions/data";
import { useHomePageChart, useHomePageYears } from "@hooks/home-page";
import { useIndicatorData } from "@hooks/use-indicator-data";
import { useRouterQuery } from "@hooks/use-router-query";
import { Flex, Text } from "@mantine/core";
import { HomeQueryParams } from "@models/home-query-params";
import { KeyLegend } from "../key-legend";

export const TitleAndLegend = () => {
  const {
    query: { indicator, display, measure },
    isParamsReady,
  } = useRouterQuery<HomeQueryParams>();

  const isTable = display === "table";

  // Get data from current years
  const { data: showPrelimAnnual } = useHomePageYears({
    select: (rawData) =>
      rawData.some(
        ({ indicatorYearDataType: { type } }) => type === "preliminaryForYear"
      ),
  });

  const { data: prelimAnnualYear } = useHomePageYears({
    select: selectAnnualPrelimYear,
  });

  const { data: showPrelimQuarterly } = useHomePageYears({
    select: selectShowQuarterly,
  });

  const { data: quarterDate } = useHomePageYears({
    select: selectQuarterDate,
  });

  const { hasGoals } = useHomePageChart();

  // Get detailed title for current indicator
  const { indicatorData } = useIndicatorData();
  const indicatorSearchId = indicator ?? Diseases.Incidence.toString();
  const activeIndicatorData = indicatorData.find(
    ({ id }) => id.toString() === indicatorSearchId
  );
  const countTitle = activeIndicatorData?.titles.cases.title;
  const percentageTitle = activeIndicatorData?.titles.rate.title;
  const defaultMeasure = activeIndicatorData?.defaultMeasure;
  const actualMeasure = measure ?? defaultMeasure;

  // When to show legend
  const showLegendOnTable: boolean =
    !!showPrelimAnnual || !!showPrelimQuarterly;

  return (
    <Flex justify="space-between" align="center" wrap="wrap" gap="xs" mt="xs">
      {isParamsReady && activeIndicatorData && actualMeasure && (
        <Text fs="italic" fz={{ base: "sm", md: "md" }}>
          {actualMeasure === "count" ? countTitle : percentageTitle}
        </Text>
      )}
      {(display !== "table" || showLegendOnTable) && (
        <KeyLegend
          prelimAnnualYear={prelimAnnualYear}
          quarterDate={quarterDate}
          showPrelimAnnual={!!showPrelimAnnual}
          showPrelimQuarterly={!!showPrelimQuarterly}
          showGoals={hasGoals}
          isTableLegend={isTable}
        />
      )}
    </Flex>
  );
};
