import { Diseases } from "@enums/diseases";
import { useHomePageDataYears, useHomePageTable } from "@hooks/home-page";
import { useRouterQuery } from "@hooks/use-router-query";
import { Button } from "@mantine/core";
import { HomeQueryParams } from "@models/home-query-params";
import { IconQuote } from "@tabler/icons-react";
import { selectYearsWithPrelimInfo } from "functions/data";
import { handleDownloadCSV } from "functions/util";
import { useTranslation } from "next-i18next";

export const DownloadCsvButton = () => {
  const { t } = useTranslation();

  const {
    query: { indicator },
  } = useRouterQuery<HomeQueryParams>();

  const key = Diseases[indicator ?? Diseases.Incidence];
  const filename = t(`common:indicators.${key}.display-name`).replace(
    /\s/g,
    "-"
  );

  // Hook into current table years and rows
  const { data: years } = useHomePageDataYears({
    select: selectYearsWithPrelimInfo,
  });
  const { rows, isTableDataLoading } = useHomePageTable();

  // Download on click
  const onDownload = () => handleDownloadCSV(rows, years, filename);

  return (
    <Button
      leftSection={<IconQuote />}
      variant="outline"
      fullWidth
      disabled={isTableDataLoading}
      onClick={onDownload}
    >
      CSV (Comma Separated Values)
    </Button>
  );
};
