import { useComputedColorScheme, useMantineTheme } from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import { getFromColorScheme } from "@utils/get-from-color-scheme";
import { useBreakpoint, useFirstTime } from "hooks";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  Locale,
  STATUS,
  Step,
} from "react-joyride";
import { useDataFilterStore, useDemographicTourStore } from "stores";
import { Tooltip } from "./tooltip";

export const GuidedTour = () => {
  const { t } = useTranslation();
  const steps = t("data:tour.steps", { returnObjects: true }) as Step[];
  const buttons = t("data:tour.buttons", { returnObjects: true }) as Locale;

  const colorScheme = useComputedColorScheme("light");
  const { colors } = useMantineTheme();
  const { isHydrated, isFirstTime, setIsFirstTime } = useFirstTime("home");
  const [, scrollTo] = useWindowScroll();

  const [isRunning, stepIndex, endTour, setIsRunning, setStepIndex] =
    useDemographicTourStore((state) => [
      state.isRunning,
      state.stepIndex,
      state.endTour,
      state.setIsRunning,
      state.setStepIndex,
    ]);
  const [activeMenuPanel, setActiveMenuPanel] = useDataFilterStore((state) => [
    state.activeMenuPanel,
    state.setActiveMenuPanel,
  ]);

  const isBelowSm = useBreakpoint("sm");
  const isFiltersOpen = activeMenuPanel === "filters";

  const handleTour = ({
    action,
    index,
    status,
    step: { target },
    type,
  }: CallBackProps) => {
    if (
      ([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status) ||
      action === ACTIONS.CLOSE
    ) {
      endTour();
      setIsFirstTime(false);
      return;
    }

    if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (nextStepIndex >= steps.length) {
        scrollTo({ y: 0 });
        endTour();
        setIsFirstTime(false);
      } else if (target === "[data-tour-id='filters-button']") {
        if (!isFiltersOpen) {
          setActiveMenuPanel("filters");
        }
        setIsRunning(false);
        setTimeout(() => {
          setIsRunning(true);
          setStepIndex(nextStepIndex);
        }, 400);
      } else if (target === "[data-tour-id='apply-filters']") {
        setActiveMenuPanel("");
        setStepIndex(nextStepIndex);
      } else {
        setStepIndex(nextStepIndex);
      }
    }
  };

  useEffect(() => {
    if (isHydrated) {
      setIsRunning(isFirstTime);
    }
  }, [isFirstTime, isHydrated, setIsRunning]);

  return (
    <>
      <Joyride
        callback={handleTour}
        continuous
        disableOverlayClose
        tooltipComponent={Tooltip}
        hideBackButton
        locale={buttons}
        run={!isBelowSm && isRunning}
        scrollDuration={400}
        scrollOffset={250}
        spotlightClicks
        stepIndex={stepIndex}
        steps={steps}
        styles={{
          options: {
            arrowColor: getFromColorScheme(
              colorScheme,
              colors.gray[0],
              colors.blue[9]
            ),
            zIndex: 600,
          },
        }}
      />
    </>
  );
};
