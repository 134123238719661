import { Disease } from "@api/diseases";
import { Badge, BadgeProps, useComputedColorScheme } from "@mantine/core";
import { getFromColorScheme } from "@utils/get-from-color-scheme";
import { indicatorBadgeColorsMap } from "./indicator-badge-color-map";
import classes from "./indicator-badge.module.css";

interface IndicatorBadgeProps extends Pick<Disease, "id">, BadgeProps {}

export const IndicatorBadge = ({
  children,
  id,
  ...rest
}: IndicatorBadgeProps) => {
  const colorScheme = useComputedColorScheme("light");
  const indicatorColor = getFromColorScheme(
    colorScheme,
    indicatorBadgeColorsMap[id].lightColor,
    indicatorBadgeColorsMap[id].darkColor
  );

  return (
    <>
      <Badge
        className={classes.badge}
        variant="light"
        color={indicatorColor}
        c={indicatorColor}
        {...rest}
      >
        {children}
      </Badge>
    </>
  );
};
