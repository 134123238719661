import { Center } from "@mantine/core";
import { ImageProps } from "next/image";
import Link from "next/link";
import { ColorSchemeImage } from "../color-scheme-image";
import classes from "./image-card.module.css";

export interface ImageCardProps extends Omit<ImageProps, "src"> {
  alt: string;
  href: string;
  src: {
    lightSrc: string;
    darkSrc: string;
  };
  target?: string;
}

export const ImageCard: React.FC<ImageCardProps> = ({
  alt,
  href,
  src,
  target,
}) => (
  <Link className={classes.link} href={href} target={target}>
    <Center className={classes.card} p={{ base: "xl", md: "2rem" }}>
      <ColorSchemeImage
        lightSrc={src.lightSrc}
        darkSrc={src.darkSrc}
        alt={alt}
        className={classes.img}
      />
    </Center>
  </Link>
);
