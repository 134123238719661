import { getAreas, getDiseases } from "api";
import { QueryKeys } from "enums";
import { QueryClient, dehydrate } from "@tanstack/react-query";

const keyFunctionMap = {
  [QueryKeys.Areas]: getAreas,
  [QueryKeys.Diseases]: getDiseases,
};

export const getStaticQueryProps = async (keys: QueryKeys[]) => {
  const queryClient = new QueryClient();
  const uniqueKeys = Array.from(new Set(keys));
  const prefetchPromises: Promise<void>[] = [];

  uniqueKeys.forEach((key) => {
    if (key in keyFunctionMap) {
      prefetchPromises.push(
        queryClient.prefetchQuery([key], keyFunctionMap[key])
      );
    }
  });

  await Promise.all(prefetchPromises);

  return { dehydratedState: dehydrate(queryClient) };
};
