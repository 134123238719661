import { useComponents } from "@hooks/use-components";
import { FC, PropsWithChildren } from "react";
import { Flex } from "@mantine/core";
import { MenuButton } from "../menu-button";
import classes from "../menu.button.module.css";

export interface GroupProps extends PropsWithChildren {}

export const Group: FC<GroupProps> = ({ children }) => {
  const MenuButtons = useComponents(children, MenuButton);

  return (
    <Flex
      direction={{ base: "row", md: "column" }}
      className={classes.buttonGroup}
    >
      {MenuButtons}
    </Flex>
  );
};
