import {
  Tooltip,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useBreakpoint } from "@hooks/use-breakpoint";
import classes from "./info-tooltip.module.css";

type TooltipPosition =
  | "bottom"
  | "left"
  | "right"
  | "top"
  | "bottom-end"
  | "bottom-start"
  | "left-end"
  | "left-start"
  | "right-end"
  | "right-start"
  | "top-end"
  | "top-start";

export const InfoTooltip = ({
  label,
  position,
  transform,
  color,
  withinPortal = false,
}: {
  label: string;
  position?: TooltipPosition;
  withinPortal?: boolean;
  transform?: string;
  color?: string;
}) => {
  const { colors } = useMantineTheme();
  const colorScheme = useComputedColorScheme("light");
  const isBelowSm = useBreakpoint("sm");

  return (
    <Tooltip
      label={label}
      multiline
      w={isBelowSm ? 250 : 373}
      position={position ?? "bottom-start"}
      transitionProps={{ transition: "pop" }}
      offset={10}
      withinPortal={withinPortal}
      classNames={{ tooltip: classes.tooltip }}
      zIndex={99999}
      events={{ hover: true, focus: true, touch: true }}
    >
      <IconInfoCircle
        transform={transform}
        width={18}
        style={{ marginLeft: "5px" }}
        color={
          color ?? (colorScheme === "dark" ? colors.blue[4] : colors.blue[7])
        }
      />
    </Tooltip>
  );
};
