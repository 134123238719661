import { CaseCounts } from "@api/case-counts";
import { useHomePageCaseCounts } from "@hooks/home-page";
import { useComputedColorScheme, useMantineTheme } from "@mantine/core";
import { STRATIFICATION_MAP } from "@constants/stratification-map";
import { useDataFilterStore } from "@stores/use-data-filter-store";
import { selectColorsFromCaseCounts } from "functions/data";
import { useCallback } from "react";

export const useHomeDataColors = () => {
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme("light");

  // Get the sort key and active confidence intervals from store
  const [activeSortKey, activeConfidenceIntervals] = useDataFilterStore(
    (state) => [state.activeSortKey, state.activeConfidenceIntervals]
  );
  const sortAttr: string | undefined =
    STRATIFICATION_MAP[activeSortKey.toLocaleLowerCase()];

  // Return query with selected colors
  return useHomePageCaseCounts({
    select: useCallback(
      (rawData: CaseCounts) =>
        selectColorsFromCaseCounts(
          rawData,
          theme,
          colorScheme,
          sortAttr,
          activeConfidenceIntervals
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [sortAttr, colorScheme, activeConfidenceIntervals]
    ),
  });
};
