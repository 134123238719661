import { TreeSelect } from "antd";
import { useAreas } from "api";
import { getAreasMultiSelectData } from "utils";
import { useRouterQuery } from "hooks";
import { HomeQueryParams } from "models";
import { useTranslation } from "next-i18next";
import { memo, useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { useDataFilterStore } from "@stores/use-data-filter-store";
import classes from "./area-multi-select.module.css";

export const AreaMultiSelect = memo(() => {
  const [opened, handlers] = useDisclosure(false);
  const [currentLocations, setCurrentLocations] = useState<string[]>([]);
  const setIsTouched = useDataFilterStore((state) => state.setIsTouched);

  const {
    query: { location },
    replaceQuery,
  } = useRouterQuery<HomeQueryParams>();
  const { t } = useTranslation(["common"]);

  const { data: areas } = useAreas();
  const multiSelectData = getAreasMultiSelectData(areas!);

  const handleChange = (locations: string[]) => {
    setCurrentLocations(locations);
  };

  const handleSubmit = async () => {
    setIsTouched(true);
    await replaceQuery({ location: currentLocations });
    setIsTouched(false);
  };

  const handleDeselect = (removedLocation: string) => {
    if (!opened) {
      replaceQuery({
        location: currentLocations.filter((l) => l !== removedLocation),
      });
    }
  };

  useEffect(() => {
    setCurrentLocations(
      // * Becuase location is from query params, it can be a string or an array
      // eslint-disable-next-line no-nested-ternary
      typeof location === "string"
        ? [location]
        : Array.isArray(location)
        ? location
        : []
    );
  }, [location]);

  const placeholder = t("tree-select.placeholder");

  return (
    <TreeSelect
      allowClear
      autoClearSearchValue={false}
      className={classes.select}
      multiple
      onChange={handleChange}
      placeholder={placeholder}
      aria-label={placeholder}
      popupClassName={classes.dropdown}
      showCheckedStrategy={TreeSelect.SHOW_CHILD}
      showSearch
      treeCheckable
      treeData={multiSelectData}
      treeNodeFilterProp="title"
      defaultValue={location ?? []}
      onBlur={handleSubmit}
      onClear={() => replaceQuery({ location: undefined })}
      onDeselect={handleDeselect}
      open={opened}
      onDropdownVisibleChange={handlers.toggle}
    />
  );
});
