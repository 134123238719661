import {
  DiseaseYearsQueryOptions,
  IndicatorYear,
  useDiseaseYears,
} from "@api/diseases";
import { Diseases } from "@enums/diseases";
import { useRouterQuery } from "@hooks/use-router-query";
import { HomeQueryParams } from "@models/home-query-params";

export const useHomePageYears = <T = IndicatorYear[]>(
  options?: DiseaseYearsQueryOptions<T>
) => {
  const {
    query: { indicator },
  } = useRouterQuery<HomeQueryParams>();

  const activeIndicator = (indicator ?? Diseases.Incidence) as Diseases;

  return useDiseaseYears(activeIndicator, {
    ...options,
  });
};
