import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "enums";
import { filterDefaultValues } from "utils";
import { racesApi } from "../axios";
import { Race } from "../race.type";

const getRaces = async () => (await racesApi.get<Race[]>("/")).data;

export const useRaces = () =>
  useQuery([QueryKeys.Races], getRaces, {
    select: (data) => filterDefaultValues(data),
  });
