import { useRouterQuery } from "@hooks/use-router-query";
import { HomeQueryParams } from "@models/home-query-params";
import { filterYearsByQuarterly } from "functions/data";
import { IndicatorYear, DiseaseYearsQueryOptions } from "@api/diseases";
import { useHomePageYears } from "../use-home-page-years";

export const useHomePageDataYears = <T = IndicatorYear[]>(
  options?: DiseaseYearsQueryOptions<T>
) => {
  const {
    query: { quarterly },
  } = useRouterQuery<HomeQueryParams>();

  const includeQuarterly = Boolean(quarterly);

  return useHomePageYears({
    ...options,
    // compose selects to use filtered quarterly data
    select: (data) => {
      const filteredData = filterYearsByQuarterly(data, includeQuarterly);
      return options?.select
        ? options.select(filteredData)
        : (filteredData as T);
    },
  });
};
