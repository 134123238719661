import { useToPng } from "@hooks/use-to-png";
import { Button } from "@mantine/core";
import { useUiStore } from "@stores/use-ui-store";
import { IconPhoto } from "@tabler/icons-react";

export const ExportToPngButton = () => {
  // hook into png export function here
  const pngRef = useUiStore((state) => state.toPngRef);
  const handleToPng = useToPng(pngRef);

  return (
    <Button
      onClick={handleToPng}
      leftSection={<IconPhoto />}
      variant="outline"
      fullWidth
      mt="md"
    >
      PNG (Graphic Image)
    </Button>
  );
};
