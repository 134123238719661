import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "enums";
import { filterDefaultValues } from "utils";
import { sexesApi } from "../axios";
import { Sex } from "../sex.type";

const getSexes = async () => (await sexesApi.get<Sex[]>("/")).data;

export const useSexes = () =>
  useQuery([QueryKeys.Sexes], getSexes, {
    select: (data) => filterDefaultValues(data),
  });
