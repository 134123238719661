import { DataLegend } from "@components/pages/data/view-data/data-legend";
import { DataTable } from "@components/pages/data/view-data/data-table";
import { LineChart } from "@components/pages/data/view-data/line-chart";
import { Sidebar } from "@components/pages/data/view-data/sidebar";
import { Box, Container, Grid, rem } from "@mantine/core";
import { useUiStore } from "@stores/use-ui-store";
import { useBreakpoint, useHeaderHeight } from "hooks";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { Disclaimer } from "./disclaimer";
import { DataSources } from "./data-sources";
import { Share } from "./share";
import { TitleAndLegend } from "./title-and-legend";
import classes from "./view-data.module.css";

export const ViewData = () => {
  const { query } = useRouter();
  const headerHeight = useHeaderHeight();
  const isBelowMedium = useBreakpoint("md");
  const toPngRef = useRef<HTMLDivElement>(null);

  const isDisplayTable = query.display === "table";

  const setToPngRef = useUiStore((state) => state.setToPngRef);
  useEffect(() => {
    setToPngRef(toPngRef);
  }, [setToPngRef, toPngRef]);

  return (
    <Container size="xl" fluid={isBelowMedium} px={{ md: "md", base: rem(0) }}>
      <Grid maw="100%" m="auto">
        <Grid.Col
          className={classes.sidebar}
          span={{ xs: 12, md: 1 }}
          px={{ base: rem(0), md: "xs" }}
          pt={{ base: rem(0), md: "xs" }}
          top={headerHeight}
        >
          <Sidebar />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, md: 11 }}>
          <Container
            size="xl"
            fluid={!isBelowMedium}
            px="xs"
            ref={toPngRef}
            data-tour-id="view-data"
          >
            <Share />
            <TitleAndLegend />
            {isDisplayTable ? <DataTable /> : <LineChart />}
            <DataLegend />
            <Box my="sm">
              <Disclaimer />
            </Box>
            <DataSources />
          </Container>
        </Grid.Col>
      </Grid>
    </Container>
  );
};
