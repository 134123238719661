import { Datatable } from "@components/ui/datatable";
import { useHomePageTable } from "@hooks/home-page";
import { ScrollArea } from "@mantine/core";
import { HomeQueryParams } from "@models/home-query-params";
import { useRouterQuery } from "hooks";
import { HomeTableHeader } from "./home-table-header";

export const DataTable = () => {
  const {
    query: { measure },
  } = useRouterQuery<HomeQueryParams>();

  const { headers, rows, colors, isTableDataLoading } = useHomePageTable();

  return (
    <ScrollArea w="100%" py="sm">
      <Datatable
        data={{
          headers,
          rows,
          colors,
          isPercentage: measure === "rate",
        }}
        isLoading={isTableDataLoading}
        Header={HomeTableHeader}
        labelColumn
      />
    </ScrollArea>
  );
};
