import { ColorSchemeImage, IndicatorCard } from "@components/ui";
import { IndicatorBadge } from "@components/ui/indicator-badge";
import { useIndicatorData } from "@hooks/use-indicator-data";
import {
  Blockquote,
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Loader,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Transition,
  getThemeColor,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import gridMenuIconDark from "@public/icons/grid-menu-icon-dark.svg";
import gridMenuIconLight from "@public/icons/grid-menu-icon-light.svg";
import { Diseases } from "enums";
import { useRouterQuery } from "hooks";
import { HomeQueryParams } from "models";
import { useTranslation } from "next-i18next";
import { useDataFilterStore } from "stores";
import classes from "./indicators.module.css";

export const Indicators = () => {
  const { t } = useTranslation(["data", "common"]);
  const [opened, handlers] = useDisclosure(false);

  const {
    query: { indicator: indicatorParam },
    replaceQuery,
  } = useRouterQuery<HomeQueryParams>();

  const { indicatorData: indicators, isLoading: isIndicatorDataLoading } =
    useIndicatorData();

  const [
    activeConfidenceIntervals,
    activeDemographicFilter,
    hiddenData,
    clearConfidenceIntervalsAndHiddenData,
    clearCustomCaseCounts,
  ] = useDataFilterStore((state) => [
    state.activeConfidenceIntervals,
    state.activeDemographicFilter,
    state.hiddenData,
    state.clearConfidenceIntervalsAndHiddenData,
    state.clearCustomCaseCounts,
  ]);

  const activeIndicator = parseInt(
    indicatorParam ?? Diseases.Incidence.toString(),
    10
  );
  const activeIndicatorData = indicators.find(
    ({ id }) => id === activeIndicator
  )!;

  const handleIndicatorClick = (
    id: number,
    defaultMeasure: "count" | "percentage"
  ) => {
    const updatedMeasure = defaultMeasure === "percentage" ? "rate" : "count";
    replaceQuery({ indicator: id, measure: updatedMeasure });

    if (activeDemographicFilter === "custom") {
      clearCustomCaseCounts();
    }
    if (hiddenData.length > 0 || activeConfidenceIntervals.length > 0) {
      clearConfidenceIntervalsAndHiddenData();
    }
  };

  return (
    <Container my="lg">
      {isIndicatorDataLoading && (
        <Center>
          <Loader />
        </Center>
      )}

      {!isIndicatorDataLoading && (
        <Stack gap="xs">
          <Group mb="xs">
            <Title order={2}>{t("data:chart-title.title")}: </Title>
            <IndicatorBadge id={activeIndicatorData.id}>
              <Title order={2}>{activeIndicatorData.displayName}</Title>
            </IndicatorBadge>
          </Group>
          <SimpleGrid data-tour-id="indicators" visibleFrom="sm" cols={6}>
            {indicators.map((indicator) => (
              <IndicatorCard
                key={`ind-card-${indicator.id}`}
                {...indicator}
                displayName={indicator.displayName
                  .toLocaleUpperCase()
                  .replace("PREP", "PrEP")}
                onSelect={() =>
                  handleIndicatorClick(indicator.id, indicator.defaultMeasure)
                }
                selected={indicator.id === activeIndicator}
              />
            ))}
          </SimpleGrid>
          <Stack hiddenFrom="sm" gap="xs">
            <Text fw="bold">Select an indicator:</Text>
            <Flex
              className={classes.indicatorSelector}
              justify="space-between"
              p="md"
              onClick={handlers.toggle}
              style={(theme) => ({
                border: `1px solid ${getThemeColor(
                  activeIndicatorData.color,
                  theme
                )}`,
              })}
            >
              <Flex align="center" gap="sm">
                <ColorSchemeImage
                  lightSrc={activeIndicatorData.src.light}
                  darkSrc={activeIndicatorData.src.dark}
                  alt="Selected indicator icon"
                  width={38}
                  unoptimized
                />
                <Text fw="bold">{activeIndicatorData.displayName}</Text>
              </Flex>
              <Flex align="center" gap="sm">
                <Divider orientation="vertical" />
                <ColorSchemeImage
                  lightSrc={gridMenuIconLight}
                  darkSrc={gridMenuIconDark}
                  alt="Grid menu icon"
                  unoptimized
                />
              </Flex>
              <Transition transition="scale-y" mounted={opened}>
                {(styles) => (
                  <Box
                    style={styles}
                    p="md"
                    className={classes.indicatorDropdown}
                  >
                    <Grid>
                      {indicators.map((indicator) => (
                        <Grid.Col span={{ base: 6 }} key={indicator.id}>
                          <IndicatorCard
                            {...indicator}
                            displayName={indicator.displayName
                              .toLocaleUpperCase()
                              .replace("PREP", "PrEP")}
                            onSelect={() =>
                              handleIndicatorClick(
                                indicator.id,
                                indicator.defaultMeasure
                              )
                            }
                            selected={indicator.id === activeIndicator}
                          />
                        </Grid.Col>
                      ))}
                    </Grid>
                  </Box>
                )}
              </Transition>
            </Flex>
          </Stack>

          <Flex mt="md">
            <Blockquote
              component="div"
              color={activeIndicatorData.color}
              py="md"
              px="lg"
            >
              <Text>{activeIndicatorData.description}</Text>{" "}
              <Text fw={700}>{activeIndicatorData.goal}</Text>
            </Blockquote>
          </Flex>
        </Stack>
      )}
    </Container>
  );
};
