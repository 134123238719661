import { ShareModal, SubscribeModal } from "@components/ui";
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Flex,
  Text,
  Title,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconArrowForwardUp, IconBellRinging } from "@tabler/icons-react";
import { formatDate } from "@utils/format-date";
import { getFromColorScheme } from "@utils/get-from-color-scheme";
import { useAreas, useCaseCountsUpdateHistoryLatest } from "api";
import { Diseases } from "enums";
import { useBreakpoint, useRouterQuery } from "hooks";
import { HomeQueryParams } from "models";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";

export const Share = () => {
  const { t } = useTranslation(["data", "common"]);
  const { colors } = useMantineTheme();
  const colorScheme = useComputedColorScheme("light");
  const isBelowSm = useBreakpoint("md");
  const {
    query: { indicator, location, subscribe },
    isParamsReady,
    replaceQuery,
  } = useRouterQuery<HomeQueryParams>();

  const [isSubscribeOpened, { open: openSubscribe, close: closeSubscribe }] =
    useDisclosure(false);
  const [isShareOpened, { open: openShare, close: closeShare }] =
    useDisclosure(false);

  useEffect(() => {
    if (isParamsReady && !!subscribe) {
      openSubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParamsReady]);

  const handleCloseSubscribe = () => {
    replaceQuery({ subscribe: null });
    closeSubscribe();
  };

  const { data: lastUpdated } = useCaseCountsUpdateHistoryLatest();
  const { data: areas } = useAreas();

  const date = formatDate(lastUpdated);
  const key = Diseases[indicator ?? Diseases.Incidence];

  let title = t("data:chart-title.national");

  if (typeof location === "string") {
    const area = areas?.find((a) => a.id.toString() === location);
    title = area?.type === "State" ? area.stateDisplayName : area?.displayName!;
  }
  if (Array.isArray(location)) {
    title = t("data:chart-title.multiple-location-sets");
  }

  return (
    <>
      <Box>
        <Flex justify="space-between" wrap="wrap" mb="xs">
          <Title order={isBelowSm ? 4 : 3}>
            {t("data:chart-title.pre-text")}{" "}
            <Text
              span
              c={getFromColorScheme(
                colorScheme,
                colors.teal[8],
                colors.teal[6]
              )}
              fw="bold"
              fz="inherit"
              className="heading"
            >
              {title}
            </Text>{" "}
            {t("data:chart-title.post-text", {
              prefix: typeof location === "string" || !location ? "" : "of",
            })}{" "}
            <Text
              span
              c={getFromColorScheme(
                colorScheme,
                colors.teal[8],
                colors.teal[6]
              )}
              fw="bold"
              fz="inherit"
              className="heading"
            >
              {t(`common:indicators.${key}.display-name`)}
            </Text>{" "}
          </Title>

          <Flex
            gap="sm"
            mt={{ base: "sm", md: 0 }}
            justify={{ base: "center", md: "flex-start" }}
            align="center"
            data-testid="share-container"
          >
            <Text fz="sm">
              <Text span fw="bold">
                {t("data:dashboard-last-updated")}
              </Text>{" "}
              {date}
            </Text>
            <Button onClick={openShare} leftSection={<IconArrowForwardUp />}>
              {t("common:share")}
            </Button>
            <ActionIcon
              onClick={openSubscribe}
              aria-label={t("common:subscription-modal.subscribe")}
            >
              <IconBellRinging />
            </ActionIcon>
          </Flex>
        </Flex>
        <Divider mt="xs" size="sm" />
      </Box>
      <SubscribeModal
        opened={isSubscribeOpened}
        onClose={handleCloseSubscribe}
      />
      <ShareModal opened={isShareOpened} onClose={closeShare} />
    </>
  );
};
