import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "enums";
import { gendersApi } from "../axios";
import { Gender } from "../gender.type";

const getGenders = async () => (await gendersApi.get<Gender[]>("/")).data;

export const useGenders = () =>
  useQuery([QueryKeys.Genders], getGenders, {
    select: (data) => data.filter((x) => x.id !== 3),
  });
