import { Meta as MetaUtil } from "@components/util";
import { useTranslation } from "next-i18next";

export const Meta = () => {
  const { t } = useTranslation();

  return (
    <MetaUtil
      title={t("data:meta.title")}
      description={t("data:meta.description")}
    />
  );
};
