export const handleDownloadCSV = (
  rows: any[] | undefined,
  years: any[] | undefined,
  filename: string
) => {
  if (!rows || !years) return;

  // Format years
  const csvYears = [
    `"Label"`,
    ...years,
    `"2025 - Goal"`,
    `"2030 - Goal"`,
  ].join();
  const csvRows = rows
    .map((row) =>
      row
        .map((cell) =>
          cell === undefined || cell === null ? "---" : `"${cell}"`
        )
        .join()
    )
    .join("\n");

  const csvContent = csvYears.concat("\n").concat(csvRows);
  const blob = new Blob([csvContent], { type: "text/csv" });

  // Create a temporary URL to download the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a temporary <a> element to trigger the download
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();

  // Clean up after download
  window.URL.revokeObjectURL(url);
};
