import { Button } from "@mantine/core";
import { IconFileSpreadsheet } from "@tabler/icons-react";
import { handleDownloadAllData } from "@utils/download-utils";

export const DownloadAllDataButton = () => (
  <Button
    leftSection={<IconFileSpreadsheet />}
    variant="outline"
    fullWidth
    onClick={handleDownloadAllData}
  >
    XLS (Excel Table File)
  </Button>
);
