import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "enums";
import { searchApi } from "../axios";
import { SearchResults } from "../search.type";

export interface SearchQuery {
  query: string;
  pageNumber: number;
  pageSize: number;
  language: string;
}

export const getSearchResults = async (
  query: SearchQuery
): Promise<SearchResults> => (await searchApi.post("/", query)).data;

export const useSearch = (query: SearchQuery) =>
  useQuery([QueryKeys.Search, query], () => getSearchResults(query));
