import { DownloadsDrawer } from "@components/pages/data/view-data/sidebar/downloads-drawer";
import { FiltersDrawer } from "@components/pages/data/view-data/sidebar/filters-drawer";
import { Menu } from "@components/pages/data/view-data/sidebar/menu";
import { Box } from "@mantine/core";
import { HelpDrawer } from "./help-drawer";
import classes from "./sidebar.module.css";

export const Sidebar = () => (
  <Box className={classes.sidebar}>
    <Menu />
    <FiltersDrawer />
    <DownloadsDrawer />
    <HelpDrawer />
  </Box>
);
