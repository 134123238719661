import { useHomePageChart, useHomePageYears } from "@hooks/home-page";
import { MeasureType } from "@icf-corp/hivgov-common-ui/line-chart/models";
import { Box } from "@mantine/core";
import { HomeQueryParams } from "@models/home-query-params";
import { useDataFilterStore } from "@stores/use-data-filter-store";
import { selectChartPrelimYears } from "@functions/chart";
import { useRouterQuery } from "@hooks";
import dynamic from "next/dynamic";
import classes from "./line-chart.module.css";

const LineChartComponent = dynamic(
  () =>
    import("@icf-corp/hivgov-common-ui/line-chart").then(
      (mod) => mod.LineChart
    ),
  { ssr: false }
);

export const LineChart = () => {
  const {
    query: { measure },
  } = useRouterQuery<HomeQueryParams>();

  // current state
  const activeMeasure = (
    measure === "rate" ? "percentage" : "count"
  ) as MeasureType;
  const activeConfidenceIntervals = useDataFilterStore(
    (state) => state.activeConfidenceIntervals
  );

  // Hook into current data
  const { data: prelimYears } = useHomePageYears({
    select: selectChartPrelimYears,
  });
  const { datasets, labels, isChartDataLoading } = useHomePageChart();

  return (
    <Box
      className={classes.chart}
      pos="relative"
      h={{ base: 350, sm: 425, lg: 475 }}
      px={{ base: "xs", md: "sm" }}
      py={{ base: 0, md: "xs" }}
      my="xs"
    >
      <LineChartComponent
        datasets={datasets}
        labels={labels}
        loaded={!isChartDataLoading}
        measureType={activeMeasure}
        prelimYears={prelimYears}
        activeCI={activeConfidenceIntervals}
        addZoom
      />
    </Box>
  );
};
