import { ActionIcon, Tooltip } from "@mantine/core";
import cx from "clsx";
import { useBreakpoint } from "hooks";
import { FC, ReactElement } from "react";
import { Group, GroupProps } from "./group";
import classes from "./menu.button.module.css";

export interface MenuButtonProps {
  active: boolean;
  disabled?: boolean;
  label: string;
  icon: ReactElement;
  onClick: () => void;
  "data-tour-id"?: string;
}

export interface MenuButtonFC extends FC<MenuButtonProps> {
  Group: FC<GroupProps>;
}

export const MenuButton: MenuButtonFC = ({
  active,
  disabled,
  label,
  icon,
  onClick,
  ...rest
}) => {
  const isBelowSmall = useBreakpoint("sm");

  return (
    <Tooltip
      label={label}
      position={isBelowSmall ? "bottom" : "right"}
      withArrow
      zIndex={440}
      disabled={disabled}
    >
      <ActionIcon
        size={isBelowSmall ? "lg" : "xl"}
        onClick={onClick}
        classNames={{ root: cx(classes.button) }}
        data-isactive={active}
        disabled={disabled}
        data-tour-id={rest["data-tour-id"]}
        aria-label={label}
      >
        {icon}
      </ActionIcon>
    </Tooltip>
  );
};

MenuButton.displayName = "MenuButton";
MenuButton.Group = Group;
