import { Diseases } from "@enums/diseases";

export const indicatorBadgeColorsMap = {
  [Diseases.Incidence]: {
    darkColor: "grape.7",
    lightColor: "grape.8",
  },
  [Diseases.KnowledgeOfStatus]: {
    darkColor: "yellow.7",
    lightColor: "yellow.9",
  },
  [Diseases.Diagnoses]: {
    darkColor: "teal.4",
    lightColor: "teal.9",
  },
  [Diseases.LinkageToCare]: {
    darkColor: "blue.7",
    lightColor: "blue.7",
  },
  [Diseases.ViralSuppression]: {
    darkColor: "red.5",
    lightColor: "red.6",
  },
  [Diseases.PrEPCoverage]: {
    darkColor: "cyan.8",
    lightColor: "cyan.8",
  },
};
