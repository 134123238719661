import { ActionIcon, Box, Flex, Transition } from "@mantine/core";
import { FC, PropsWithChildren, forwardRef, RefAttributes } from "react";
import { useBreakpoint, useComponents } from "@hooks";
import { IconChevronLeft } from "@tabler/icons-react";
import { useDataFilterStore } from "@stores/use-data-filter-store";
import { useTranslation } from "next-i18next";
import { Panel, PanelProps } from "./panel";
import classes from "./drawer.module.css";

export interface DrawerProps extends PropsWithChildren {
  mounted: boolean;
}

export interface DrawerFC
  extends FC<DrawerProps & RefAttributes<HTMLDivElement>> {
  Panel: FC<PanelProps>;
}

const DrawerComponent: FC<DrawerProps> = forwardRef<
  HTMLDivElement,
  DrawerProps
>(({ mounted, children }, ref) => {
  const Panels = useComponents(children, Panel);
  const { t } = useTranslation(["common"]);
  const isBelowMd = useBreakpoint("md");

  const [activeMenuPanel, isTouched, setActiveMenuPanel, setShowError] =
    useDataFilterStore((state) => [
      state.activeMenuPanel,
      state.isTouched,
      state.setActiveMenuPanel,
      state.setShowError,
    ]);

  const handleCloseClick = () => {
    if (activeMenuPanel === "filters" && isTouched) {
      setShowError(true);
      return;
    }
    setActiveMenuPanel("");
  };

  return (
    <Transition
      mounted={mounted}
      transition={isBelowMd ? "scale-y" : "scale-x"}
      timingFunction="ease"
    >
      {(styles) => (
        <Flex style={styles} className={classes.drawer} ref={ref}>
          {Panels.map((panel) => (
            <Box className={classes.panel} key={crypto.randomUUID()}>
              {panel}
            </Box>
          ))}
          {!isBelowMd && (
            <Box>
              <ActionIcon
                className={classes.close}
                onClick={handleCloseClick}
                aria-label={t("close")}
              >
                <IconChevronLeft />
              </ActionIcon>
            </Box>
          )}
        </Flex>
      )}
    </Transition>
  );
});

export const Drawer = DrawerComponent as DrawerFC;
Drawer.Panel = Panel;
