import { Flex, Text } from "@mantine/core";
import diamondBlack from "@public/icons/diamond-black.svg";
import diamondWhite from "@public/icons/diamond-white.svg";
import ellipseBlack from "@public/icons/ellipse-black.svg";
import ellipseWhite from "@public/icons/ellipse-white.svg";
import openEllipseBlack from "@public/icons/open-ellipse-black.svg";
import openEllipseWhite from "@public/icons/open-ellipse-white.svg";
import triangleBlack from "@public/icons/triangle-black.svg";
import triangleWhite from "@public/icons/triangle-white.svg";
import { useTranslation } from "next-i18next";
import { useRouterQuery } from "hooks";
import { HomeQueryParams } from "models";
import { Diseases } from "@enums/diseases";
import { KeyItem } from "./key-item";
import classes from "./key-legend.module.css";

export interface KeyLegendProps {
  prelimAnnualYear: string | undefined;
  quarterDate: string | undefined;
  showPrelimAnnual: boolean;
  showPrelimQuarterly: boolean;
  showGoals: boolean;
  isTableLegend: boolean;
}

export const KeyLegend = ({
  prelimAnnualYear,
  quarterDate,
  showPrelimAnnual,
  showPrelimQuarterly,
  showGoals,
  isTableLegend,
}: KeyLegendProps) => {
  const { t } = useTranslation(["data"]);
  const {
    query: { quarterly, indicator },
    replaceQuery,
  } = useRouterQuery<HomeQueryParams>();

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    replaceQuery({ quarterly: e.currentTarget.checked });
  };

  const activeIndicator = indicator;
  const prelimAnnualDescription =
    activeIndicator === Diseases.PrEPCoverage.toString()
      ? t("key-legend.tooltip.prelim-annual-prep")
      : t("key-legend.tooltip.prelim-annual", {
          year: prelimAnnualYear,
        });

  return (
    <Flex
      gap="sm"
      align="flex-start"
      justify="center"
      className={classes.wrapper}
      px="xs"
      py={6}
    >
      <Text fw="bold" fz="sm">
        Key:
      </Text>
      <Flex gap="sm" wrap="wrap">
        <KeyItem
          name={t("key-legend.labels.provisional")}
          description={t("key-legend.tooltip.provisional")}
          src={{ light: ellipseBlack, dark: ellipseWhite }}
          alt="Provisional data point"
          hidden={isTableLegend}
        />
        <KeyItem
          name={t("key-legend.labels.prelim-annual")}
          description={prelimAnnualDescription}
          src={{ light: openEllipseBlack, dark: openEllipseWhite }}
          alt="Preliminary data point"
          hidden={!showPrelimAnnual}
          isTable={isTableLegend}
          tableSymbol="†"
          href="/about/glossary/?terms=m-r"
        />
        <KeyItem
          name={t("key-legend.labels.goals")}
          src={{ light: triangleBlack, dark: triangleWhite }}
          alt="Goal data point"
          hidden={!showGoals || isTableLegend}
        />
        <KeyItem
          name={`Preliminary as of ${quarterDate}`}
          description={t("key-legend.tooltip.prelim-quarterly")}
          src={{ light: diamondBlack, dark: diamondWhite }}
          alt="Preliminary quarter data point"
          defaultChecked={quarterly === "true"}
          showCheckbox
          onClick={handleClick}
          hidden={!showPrelimQuarterly || !quarterDate}
          isTable={isTableLegend}
          tableSymbol="‡"
        />
      </Flex>
    </Flex>
  );
};
