import { ALL_YEARS } from "@constants/all-years";
import { useRouterQuery } from "@hooks/use-router-query";
import {
  ChartDataset,
  MeasureType,
} from "@icf-corp/hivgov-common-ui/line-chart/models";
import { useMantineTheme, useComputedColorScheme } from "@mantine/core";
import { HomeQueryParams } from "@models/home-query-params";
import { STRATIFICATION_MAP } from "@constants/stratification-map";
import { useDataFilterStore } from "@stores/use-data-filter-store";
import {
  getDataLabel,
  getLineColor,
  goalsToChartGoalDataPoints,
  startingColorIndex,
  valuesToChartData,
} from "functions/chart";
import {
  getCIByMeasure,
  getCaseCountsDataByYear,
  getCaseCountsGoalsByYear,
  getValuesFromData,
  selectPrelimIndices,
  selectYears,
  sortCaseCountsStrats,
} from "functions/data";
import {
  useHomePageCaseCounts,
  useHomePageDataYears,
  useHomePageYears,
} from "..";

export const useHomePageChart = () => {
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme("light");

  const {
    query: { measure },
  } = useRouterQuery<HomeQueryParams>();

  const activeMeasure = (
    measure === "rate" ? "percentage" : "count"
  ) as MeasureType;

  const hiddenData = useDataFilterStore((state) => state.hiddenData);
  const activeSortKey = useDataFilterStore((state) => state.activeSortKey);
  const sortAttr: string | undefined =
    STRATIFICATION_MAP[activeSortKey.toLocaleLowerCase()];

  const { data: years } = useHomePageDataYears({
    select: selectYears,
  });

  const { data: preliminaryIndexes, isLoading: isPrelimLoading } =
    useHomePageYears({
      select: selectPrelimIndices("preliminaryForYear"),
    });

  const { data: quarterlyIndexes, isLoading: isQuarterlyLoading } =
    useHomePageYears({
      select: selectPrelimIndices("preliminaryForQuarter"),
    });

  const { data: datasets, isLoading: isChartDataLoading } =
    useHomePageCaseCounts<ChartDataset[]>({
      enabled: !isPrelimLoading && !isQuarterlyLoading,
      select: (rawData) => {
        const hasGeographicFilter = rawData.filters.areaId?.some(
          // TODO There is an issue with the datatypes here, they are actually numeric but mistyped...
          // eslint-disable-next-line eqeqeq
          (a) => a != "0"
        );
        return sortCaseCountsStrats(rawData, sortAttr).map(({ strat, i }) => {
          const dataByYear = getCaseCountsDataByYear(
            rawData.data[i].data,
            years ?? []
          );
          const goalsByYear = getCaseCountsGoalsByYear(rawData.data[i].goals);

          const label = getDataLabel(strat);
          const colorIndex =
            (hasGeographicFilter ? 0 : startingColorIndex[strat.diseaseId]) + i;
          return {
            data: valuesToChartData(
              getValuesFromData(dataByYear, activeMeasure)
            ),
            goals: goalsToChartGoalDataPoints(goalsByYear, activeMeasure),
            label,
            color: getLineColor(theme, colorScheme, colorIndex),
            preliminaryIndexes,
            quarterlyIndexes,
            labelKeys: strat,
            lci: getCIByMeasure(dataByYear, "lower", activeMeasure),
            uci: getCIByMeasure(dataByYear, "upper", activeMeasure),
            // ! Not working correctly all the time
            isHidden: hiddenData.includes(label),
            showGoalsNote: goalsByYear.some((g) => g?.note),
          };
        });
      },
    });

  const hasGoals = !!datasets?.some((d) =>
    d.goals?.every((p) => p.value !== "" && p.value !== "0")
  );

  const labels = ALL_YEARS.map((year) => (year === "2020" ? "2020*" : year));

  return {
    datasets: datasets ?? [],
    labels,
    hasGoals,
    isChartDataLoading,
  };
};
