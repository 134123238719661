import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "enums";
import { filterDefaultValues } from "utils";
import { ageGroupsApi } from "../axios";
import { AgeGroup } from "../age-group.type";

const getAgeGroups = async () => (await ageGroupsApi.get<AgeGroup[]>("/")).data;

export const useAgeGroups = () =>
  useQuery([QueryKeys.AgeGroups], getAgeGroups, {
    select: (data) => filterDefaultValues(data),
  });
