import { ActionIcon, Box, Button, Flex, Text, Title } from "@mantine/core";
import { Step, TooltipRenderProps } from "react-joyride";
import { IconX } from "@tabler/icons-react";
import classes from "./tooltip.module.css";

interface CustomStep extends Step {
  stepNumber?: number;
  footerContent?: string;
}

export interface TooltipProps extends Omit<TooltipRenderProps, "step"> {
  step: CustomStep;
}

export const Tooltip = ({
  closeProps,
  primaryProps,
  step,
  tooltipProps,
}: TooltipProps) => (
  <Box
    {...tooltipProps}
    className={classes.container}
    p="xl"
    w={{ base: 320, md: 368 }}
  >
    <Flex mb="md" justify="space-between" align="center">
      <Flex align="center" gap="xs">
        {step.stepNumber && (
          <Box className={classes.stepNumber}>{step.stepNumber}</Box>
        )}
        <Title order={2} size="large">
          {step.title}
        </Title>
      </Flex>
      <ActionIcon {...closeProps} size="sm" className={classes.closeButton}>
        <IconX />
      </ActionIcon>
    </Flex>
    <Text>{step.content}</Text>
    <Flex justify="space-between" align="center" mt="md">
      <Button {...closeProps} variant="outline">
        {closeProps.title}
      </Button>
      {step.footerContent && <Text>{step.footerContent}</Text>}
      <Button {...primaryProps}>{primaryProps.title}</Button>
    </Flex>
  </Box>
);
