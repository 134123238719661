import { Drawer } from "@components/pages/data/view-data/sidebar/drawer";
import { Link } from "@components/ui";
import {
  Anchor,
  Divider,
  Flex,
  Stack,
  Text,
  Title,
  UnstyledButton,
  useComputedColorScheme,
  useMantineTheme,
  alpha,
} from "@mantine/core";
import { IconPointer } from "@tabler/icons-react";
import { getFromColorScheme } from "@utils/get-from-color-scheme";
import { useBreakpoint } from "hooks";
import { useTranslation } from "next-i18next";
import { useDataFilterStore, useDemographicTourStore } from "stores";
import classes from "./help-drawer.module.css";

export const HelpDrawer = () => {
  const { t } = useTranslation(["data"]);
  const { colors, black } = useMantineTheme();
  const colorScheme = useComputedColorScheme("light");
  const isBelowSm = useBreakpoint("sm");

  const [activeMenuPanel, setActiveMenuPanel] = useDataFilterStore((state) => [
    state.activeMenuPanel,
    state.setActiveMenuPanel,
  ]);
  const setIsRunning = useDemographicTourStore((state) => state.setIsRunning);

  const handleTourClick = () => {
    setActiveMenuPanel("");
    setIsRunning(true);
  };

  return (
    <Drawer mounted={activeMenuPanel === "help"}>
      <Drawer.Panel>
        <Title order={3}>{t("help-drawer.need-help")} </Title>
        <Divider
          color={getFromColorScheme(colorScheme, colors.gray[3], black)}
          my="md"
        />
        {!isBelowSm && (
          <UnstyledButton
            className={classes.tourButton}
            onClick={handleTourClick}
            style={{
              "--light-button-hover-bg-color": alpha(colors.blue[7], 0.1),
              "--dark-button-hover-bg-color": alpha(colors.blue[4], 0.1),
            }}
          >
            <Flex align="center" justify="space-between">
              <Text size="sm">
                {t("help-drawer.activate-interactive-guide")}
              </Text>
              <Flex
                className={classes.tourButtonIcon}
                direction="column"
                align="center"
              >
                <IconPointer size={16} />
                <Text inherit>{t("help-drawer.follow-along")}</Text>
              </Flex>
            </Flex>
          </UnstyledButton>
        )}
        <Stack gap="md" mt="md">
          {/* <Link href="/help">Accessible User Guide</Link> */}
          <Text c="dimmed" td="underline">
            {t("help-drawer.accessible-user-guide")}
          </Text>
          <Link href="/about/faq">{t("help-drawer.faq")}</Link>
          {/* <Link href="/help">Video Walkthrough</Link> */}
        </Stack>
        <Title order={5} mt="xl">
          {t("help-drawer.related-info")}
        </Title>
        <Stack gap="md" mt="xs">
          <Anchor href="https://www.hiv.gov/federal-response/ending-the-hiv-epidemic/overview/">
            {t("help-drawer.what-is-ehe")}
          </Anchor>
          <Link href="/ehe/indicators/incidence">
            {t("help-drawer.what-are-the-indicators")}
          </Link>
          <Link href="/about/faq/?section=data">
            {t("help-drawer.how-is-data-collected")}
          </Link>
          <Link href="/about/faq/?section=use">
            {t("help-drawer.how-do-i-share")}
          </Link>
          <Link href="/about/faq/?section=overview">
            {t("help-drawer.how-often-is-data-updated")}
          </Link>
          <Link href="/data/geographic/sdoh/">
            {t("help-drawer.more-data-on-sdoh")}
          </Link>
        </Stack>
        <Title order={5} mt="xl" mb="xs">
          {t("help-drawer.additional-help")}
        </Title>
        <Anchor href="mailto:contact@hiv.gov">
          {t("help-drawer.schedule-ta-session")}
        </Anchor>
        <Title order={5} mt="xl" mb="xs">
          {t("help-drawer.give-feedback")}
        </Title>
        <Text size="sm">
          {t("help-drawer.to-provide-feedback")}{" "}
          <Anchor href="mailto:contact@hiv.gov">contact@hiv.gov</Anchor>
        </Text>
      </Drawer.Panel>
    </Drawer>
  );
};
