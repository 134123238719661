import { Drawer } from "@components/pages/data/view-data/sidebar/drawer";
import {
  Divider,
  Text,
  Title,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { getFromColorScheme } from "@utils/get-from-color-scheme";
import { useDataFilterStore } from "stores";
import { DownloadAllDataButton } from "./download-all-data-button";
import { DownloadCsvButton } from "./download-csv-button";
import { ExportToPngButton } from "./export-to-png-button";

// todo: localize text
export const DownloadsDrawer = () => {
  const { colors, black } = useMantineTheme();
  const colorScheme = useComputedColorScheme("light");

  const activeMenuPanel = useDataFilterStore((state) => state.activeMenuPanel);

  return (
    <Drawer mounted={activeMenuPanel === "downloads"}>
      <Drawer.Panel>
        <Title order={3}>Downloads</Title>
        <Divider
          color={getFromColorScheme(colorScheme, colors.gray[3], black)}
          my="md"
        />
        <Text>
          AHEAD data has been made available in different formats for you to
          download and review. Click on the file type below to download.
        </Text>
        <Text fw="bold" mt="md">
          Active Data
        </Text>
        <Text fs="italic" mb="md" size="sm">
          Download data for only selected filters.
        </Text>
        <DownloadCsvButton />
        <ExportToPngButton />
        <Text fw="bold" mt="md">
          All Data
        </Text>
        <Text fs="italic" mb="md" size="sm">
          Download entire data set.
        </Text>
        <DownloadAllDataButton />
      </Drawer.Panel>
    </Drawer>
  );
};
