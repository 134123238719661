import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "enums";
import { filterDefaultValues } from "utils";
import { transmissionsApi } from "../axios";
import { Transmission } from "../transmission.type";

const getTransmissions = async () =>
  (await transmissionsApi.get<Transmission[]>("/")).data;

export const useTransmissions = () =>
  useQuery([QueryKeys.Transmissions], getTransmissions, {
    select: (data) => filterDefaultValues(data),
  });
