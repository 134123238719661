import { useHomePageChart } from "@hooks/home-page";
import { DataLegend as DataLegendComponent } from "@icf-corp/hivgov-common-ui/data-legend";
import { useComputedColorScheme } from "@mantine/core";
import { HomeQueryParams } from "@models/home-query-params";
import { useRouterQuery } from "hooks";
import { useDataFilterStore } from "stores";

export const DataLegend = () => {
  const colorScheme = useComputedColorScheme("light");
  const {
    query: { display },
  } = useRouterQuery<HomeQueryParams>();

  // current state
  const [
    activeConfidenceIntervals,
    addConfidenceInterval,
    addHiddenData,
    removeConfidenceInterval,
    removeHiddenData,
    setActiveSortKey,
  ] = useDataFilterStore((state) => [
    state.activeConfidenceIntervals,
    state.addConfidenceInterval,
    state.addHiddenData,
    state.removeConfidenceInterval,
    state.removeHiddenData,
    state.setActiveSortKey,
  ]);

  // hook into data
  const { datasets } = useHomePageChart();

  // component functions
  const handleSortClick = (sortKey: string, toggle: boolean) =>
    setActiveSortKey(toggle ? sortKey : "");

  const handleCIClick = (index: number, checked: boolean) =>
    checked ? addConfidenceInterval(index) : removeConfidenceInterval(index);

  // ! Indices are not working, need to use something unique, like label
  const handleHiddenClick = (uid: string, checked: boolean) =>
    checked ? removeHiddenData(uid) : addHiddenData(uid);

  // render variables
  const shouldShowVisToggle = !display || display === "chart";

  return (
    <DataLegendComponent
      activeCI={activeConfidenceIntervals}
      darkMode={colorScheme === "dark"}
      datasets={datasets}
      onSort={handleSortClick}
      onToggleCI={handleCIClick}
      onToggleVis={handleHiddenClick}
      showVisToggle={shouldShowVisToggle}
      showCIToggle
      visible
    />
  );
};
