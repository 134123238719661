import {
  CaseCounts,
  CaseCountsQueryOptions,
  Filters,
  useCaseCountsStratified,
} from "@api/case-counts";
import { Diseases } from "@enums/diseases";
import { useRouterQuery } from "@hooks/use-router-query";
import { HomeQueryParams } from "@models/home-query-params";
import { useDataFilterStore } from "@stores/use-data-filter-store";
import { stratEquals } from "@utils/strat-equals";

export const useHomePageCaseCounts = <T = CaseCounts>(
  options?: CaseCountsQueryOptions<T>
) => {
  const {
    query: { age, gender, indicator, location, race, sex, transmission },
  } = useRouterQuery<HomeQueryParams>();

  const [
    customFilters,
    setCustomFilters,
    customCaseCounts,
    addCustomCaseCounts,
    clearCustomCaseCounts,
  ] = useDataFilterStore((state) => [
    state.customFilters,
    state.setCustomFilters,
    state.customCaseCounts,
    state.addCustomCaseCounts,
    state.clearCustomCaseCounts,
  ]);
  const activeDemographicFilter = useDataFilterStore(
    (state) => state.activeDemographicFilter
  );

  const isStratifiedFilter = activeDemographicFilter === "stratified";
  const activeIndicator = indicator ?? Diseases.Incidence.toString();
  const genderId = location === undefined ? gender : undefined;

  const filters: Filters = {
    ageGroupId: age,
    areaId: location,
    diseaseId: [activeIndicator],
    genderId,
    raceId: race,
    transmissionId: transmission,
    sexId: sex,
  };
  const defaultCustomFilter: Filters = {
    diseaseId: [activeIndicator],
  };
  const customFiltersDefined = () => Object.keys(customFilters).length;

  const preselect: (data: CaseCounts) => CaseCounts = (data: CaseCounts) => {
    if (!isStratifiedFilter && customFiltersDefined()) {
      // TODO think of a way to refactor this, needed since area selections are not single filter and actually stratified across other filters
      const distinctData: CaseCounts = {
        filters: data.filters,
        data: [],
        strats: [],
      };
      data.strats.forEach((s0, i) => {
        const existingStrat = customCaseCounts?.strats.some((s1) =>
          stratEquals(s0, s1)
        );
        if (!existingStrat) {
          distinctData.data.push(data.data[i]);
          distinctData.strats.push(data.strats[i]);
        }
      });
      addCustomCaseCounts(distinctData);
      setCustomFilters({});
    } else if (isStratifiedFilter && customCaseCounts?.data.length) {
      clearCustomCaseCounts();
    }
    const displayCustomCounts =
      !isStratifiedFilter && customCaseCounts?.data.length;
    return {
      filters: data.filters,
      strats: [
        ...(displayCustomCounts ? customCaseCounts.strats : data.strats),
      ],
      data: [...(displayCustomCounts ? customCaseCounts.data : data.data)],
    };
  };

  let activeFilter: Filters;
  if (isStratifiedFilter) {
    activeFilter = filters;
  } else if (customFiltersDefined()) {
    activeFilter = customFilters;
  } else {
    activeFilter = defaultCustomFilter;
  }

  const caseCounts = useCaseCountsStratified(activeFilter, {
    ...options,
    select: (data) => {
      const result = preselect(data);
      if (options?.select) {
        return options.select(result);
      }
      return result as T;
    },
    keepPreviousData: !isStratifiedFilter,
  });

  return caseCounts;
};
