import {
  Checkbox,
  Combobox,
  ComboboxItem,
  Group,
  Input,
  Pill,
  PillsInput,
  PillsInputFieldProps,
  PillsInputProps,
  useCombobox,
} from "@mantine/core";
import classes from "./filters-multi-select.module.css";

// todo: fix ref error with input props
export interface FiltersMultiSelectProps
  extends Omit<PillsInputFieldProps, "value" | "onChange"> {
  value: string[];
  onChange: (value: string[]) => void;
  onDropdownClose?: () => void;
  label: PillsInputProps["label"];
  options: ComboboxItem[];
  enableSelectAll?: boolean;
  maxValues?: number;
}

export const FiltersMultiSelect = ({
  value,
  onChange,
  onDropdownClose,
  onBlur,
  onKeyDown,
  label,
  disabled,
  options,
  placeholder,
  enableSelectAll = false,
  maxValues = Infinity,
  ...rest
}: FiltersMultiSelectProps) => {
  const combobox = useCombobox({
    onDropdownClose: () => {
      onDropdownClose?.();
      combobox.resetSelectedOption();
    },
  });

  const getOptionByValue = (val: string) =>
    options.find((o) => o.value === val);

  const handleOptionSelect = (val: string) => {
    if (enableSelectAll && val === "selectAll") {
      if (value.length === options.length) {
        onChange([]);
      } else {
        onChange(options.map((o) => o.value));
      }

      return;
    }

    const selectedOption = options.find((o) => o.value === val);
    const selectedValue = selectedOption?.value;

    if (selectedValue) {
      onChange(
        value.includes(selectedValue)
          ? value.filter((v) => v !== selectedValue)
          : [...value, selectedValue]
      );
    }
  };

  const handleOptionDeselect = (val: string) =>
    onChange(value.filter((v) => v !== val));

  const values = value.map((item) => (
    <Pill
      key={item}
      withRemoveButton
      onRemove={() => handleOptionDeselect(item)}
      radius="sm"
    >
      {getOptionByValue(item)?.label}
    </Pill>
  ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleOptionSelect}
      withinPortal={false}
      disabled={disabled}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          pointer
          label={label}
          labelProps={{ fw: "bold" }}
          onClick={() => combobox.toggleDropdown()}
          disabled={disabled}
          rightSection={
            value.length > 0 ? (
              <Combobox.ClearButton
                size="sm"
                onClear={() => onChange([])}
                aria-label="Clear value"
                color="blue"
              />
            ) : (
              <Combobox.Chevron />
            )
          }
          rightSectionPointerEvents={value.length > 0 ? "all" : "none"}
        >
          <Pill.Group>
            {values.length > 0 ? (
              values
            ) : (
              <Input.Placeholder className={classes.placeholder}>
                {placeholder}
              </Input.Placeholder>
            )}

            <Combobox.EventsTarget>
              <PillsInput.Field
                type="hidden"
                {...rest}
                onBlur={(e) => {
                  combobox.closeDropdown();
                  onBlur?.(e);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Backspace") {
                    event.preventDefault();
                    handleOptionDeselect(value[value.length - 1]);
                  }
                  onKeyDown?.(event);
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>
          {enableSelectAll && (
            <Combobox.Option
              value="selectAll"
              key="selectAll"
              active={value.length === options.length}
            >
              <Group gap="sm">
                <Checkbox
                  checked={value.length > 0}
                  indeterminate={
                    value.length > 0 && value.length < options.length
                  }
                  onChange={() => {}}
                  aria-hidden
                  tabIndex={-1}
                  style={{ pointerEvents: "none", fontWeight: "bold" }}
                  label="Select All"
                />
              </Group>
            </Combobox.Option>
          )}
          {options.map(({ value: itemValue, label: itemLabel }) => (
            <Combobox.Option
              value={itemValue}
              key={itemValue}
              active={value.includes(itemValue)}
              disabled={!value.includes(itemValue) && value.length >= maxValues}
            >
              <Group gap="sm" wrap="nowrap">
                <Checkbox
                  checked={value.includes(itemValue)}
                  onChange={() => {}}
                  aria-hidden
                  tabIndex={-1}
                  style={{ pointerEvents: "none" }}
                  label={itemLabel}
                />
              </Group>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
