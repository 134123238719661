import { ColorSchemeImage, Link } from "@components/ui";
import { Checkbox, Group, Text } from "@mantine/core";
import { useTranslation } from "next-i18next";
import { InfoTooltip } from "../info-tooltip/info-tooltip";
import classes from "./key-item.module.css";

interface KeyItemProps {
  alt: string;
  defaultChecked?: boolean;
  description?: string | null;
  hidden?: boolean;
  name: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  src: {
    light: string;
    dark: string;
  };
  isTable?: boolean;
  tableSymbol?: string;
  showCheckbox?: boolean;
  href?: string;
}

export const KeyItem = ({
  alt,
  src,
  isTable,
  tableSymbol,
  defaultChecked,
  description,
  hidden,
  name,
  onClick,
  showCheckbox,
  href,
}: KeyItemProps) => {
  const { t } = useTranslation(["data"]);
  const checkboxLabel = t("key-legend.checkbox-label");

  return (
    <Group
      wrap="nowrap"
      gap={0}
      align="center"
      display={hidden ? "none" : undefined}
    >
      {isTable && tableSymbol}
      {/* todo: hide on table view */}
      <ColorSchemeImage
        lightSrc={src.light}
        darkSrc={src.dark}
        alt={alt}
        width={10}
        height={10}
        hidden={isTable}
      />
      {href && (
        <Link href={href} className={classes.link} target="_blank">
          <Text className={classes.text}>{name}</Text>
        </Link>
      )}
      {!href && <Text className={classes.text}>{name}</Text>}
      {showCheckbox && (
        <Checkbox
          onClick={onClick}
          ml="xs"
          defaultChecked={defaultChecked}
          aria-label={checkboxLabel}
        />
      )}
      {description && <InfoTooltip label={description} />}
    </Group>
  );
};
