import { Grid, Title, Box } from "@mantine/core";
import React from "react";
import cdcLogoDark from "@public/images/cdc-logo-dark.svg";
import cdcLogoLight from "@public/images/cdc-logo-light.svg";
import hivLogoLight from "@public/images/hiv-gov-logo-light.svg";
import hivLogoDark from "@public/images/hiv-gov-logo-dark.svg";
import eheLogoDark from "@public/images/ehe-logo-dark.svg";
import eheLogoLight from "@public/images/ehe-logo-light.svg";
import atlasPlusLogoDark from "@public/images/atlas-plus-logo-dark.svg";
import atlasPlusLogoLight from "@public/images/atlas-plus-logo-light.svg";
import ihsLogoDark from "@public/images/ihs-logo-dark.svg";
import ihsLogoLight from "@public/images/ihs-logo-light.svg";
import { ImageCard, ImageCardProps } from "@components/ui/image-card";

const images: ImageCardProps[] = [
  {
    src: { lightSrc: hivLogoLight, darkSrc: hivLogoDark },
    alt: "HIV.gov logo",
    href: "https://www.hiv.gov/",
    target: "_blank",
  },
  {
    src: { lightSrc: eheLogoLight, darkSrc: eheLogoDark },
    alt: "EHE logo",
    href: "https://www.hiv.gov/federal-response/ending-the-hiv-epidemic/overview/",
    target: "_blank",
  },
  {
    src: { lightSrc: atlasPlusLogoLight, darkSrc: atlasPlusLogoDark },
    alt: "Atlas Plus logo",
    href: "https://www.cdc.gov/nchhstp/atlas",
    target: "_blank",
  },
  {
    src: { lightSrc: ihsLogoLight, darkSrc: ihsLogoDark },
    alt: "Indian Health Service logo",
    href: "https://www.ihs.gov/",
    target: "_blank",
  },
  {
    src: { lightSrc: cdcLogoLight, darkSrc: cdcLogoDark },
    alt: "CDC logo",
    href: "https://www.cdc.gov/",
    target: "_blank",
  },
];

export const InitiativesPartners = () => (
  <Box>
    <Title ta="center" order={2} mb={{ md: "xl" }}>
      AHEAD Initiatives and Partners
    </Title>
    <Grid gutter="lg" justify="center" mt="sm">
      {images.map((img) => (
        <Grid.Col
          span={{ base: 6, sm: "auto" }}
          h={{
            base: "9rem",
            md: "11.5rem",
          }}
          key={img.alt}
        >
          <ImageCard {...img} />
        </Grid.Col>
      ))}
    </Grid>
  </Box>
);
