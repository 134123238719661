import {
  Divider,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import {
  IconAdjustmentsHorizontal,
  IconDownload,
  IconHash,
  IconHelp,
  IconPercentage,
  IconTable,
  IconTimeline,
} from "@tabler/icons-react";
import { useBreakpoint, useIndicatorData, useRouterQuery } from "hooks";
import { HomeQueryParams } from "models";
import { useTranslation } from "next-i18next";
import { useDataFilterStore, useDemographicTourStore } from "stores";
import { getFromColorScheme, isRateAllowed } from "utils";
import { Diseases } from "@enums/diseases";
import { MenuButton } from "./menu-button";

export const Menu = () => {
  const { colors, white } = useMantineTheme();
  const colorScheme = useComputedColorScheme("light");

  const {
    query: { display, indicator, measure },
    isParamsReady,
    replaceQuery,
  } = useRouterQuery<HomeQueryParams>();

  const { t } = useTranslation(["data"]);
  const isBelowMedium = useBreakpoint("md");

  const [
    activeMenuPanel,
    setActiveMenuPanel,
    clearConfidenceIntervalsAndHiddenData,
  ] = useDataFilterStore((state) => [
    state.activeMenuPanel,
    state.setActiveMenuPanel,
    state.clearConfidenceIntervalsAndHiddenData,
  ]);
  const [isRunning, stepIndex, setStepIndex] = useDemographicTourStore(
    (state) => [state.isRunning, state.stepIndex, state.setStepIndex]
  );

  const divColor = getFromColorScheme(colorScheme, colors.gray[3], white);
  const divOrientation = isBelowMedium ? "vertical" : "horizontal";

  const handleFiltersClick = () => {
    setActiveMenuPanel("filters");

    if (isRunning) {
      setStepIndex(stepIndex + 1);
    }
  };

  const handleMeasureClick = (newMeasure: "count" | "rate") => {
    clearConfidenceIntervalsAndHiddenData();
    replaceQuery({ measure: newMeasure });
  };

  // Logic for defaultMeasure
  const { indicatorData } = useIndicatorData();
  const indicatorSearchId = indicator ?? Diseases.Incidence.toString();
  const activeIndicatorData = indicatorData.find(
    ({ id }) => id.toString() === indicatorSearchId
  );
  const defaultMeasure = activeIndicatorData?.defaultMeasure;
  const actualMeasure = measure ?? defaultMeasure;

  return (
    <>
      <MenuButton
        label={t("sidebar.buttons.filters")}
        icon={<IconAdjustmentsHorizontal />}
        onClick={handleFiltersClick}
        active={activeMenuPanel === "filters"}
        data-tour-id="filters-button"
      />
      <Divider color={divColor} orientation={divOrientation} />
      <MenuButton.Group>
        <MenuButton
          label={t("sidebar.buttons.chart")}
          icon={<IconTimeline />}
          onClick={() => replaceQuery({ display: "chart" })}
          active={display !== "table"}
        />
        <MenuButton
          label={t("sidebar.buttons.table")}
          icon={<IconTable />}
          onClick={() => replaceQuery({ display: "table" })}
          active={display === "table"}
        />
      </MenuButton.Group>
      <MenuButton.Group>
        <MenuButton
          disabled={!isParamsReady || actualMeasure === undefined}
          label={t("sidebar.buttons.count")}
          icon={<IconHash />}
          onClick={() => handleMeasureClick("count")}
          active={actualMeasure === "count"}
        />
        <MenuButton
          label={t("sidebar.buttons.rate")}
          icon={<IconPercentage />}
          onClick={() => handleMeasureClick("rate")}
          active={actualMeasure !== "count"}
          disabled={
            !isParamsReady ||
            actualMeasure === undefined ||
            !isRateAllowed(indicator)
          }
        />
      </MenuButton.Group>
      <Divider color={divColor} orientation={divOrientation} />
      <MenuButton
        label={t("sidebar.buttons.downloads")}
        icon={<IconDownload />}
        onClick={() => setActiveMenuPanel("downloads")}
        active={activeMenuPanel === "downloads"}
      />
      <MenuButton
        label={t("sidebar.buttons.help")}
        icon={<IconHelp />}
        onClick={() => setActiveMenuPanel("help")}
        active={activeMenuPanel === "help"}
      />
    </>
  );
};
