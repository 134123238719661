import { Diseases } from "@enums/diseases";
import { useRouterQuery } from "@hooks";
import { Text, Box, Anchor } from "@mantine/core";
import { HomeQueryParams } from "@models/home-query-params";
import { useTranslation } from "next-i18next";
import { useHomePageCaseCounts } from "@hooks/home-page";
import classes from "./disclaimer.module.css";

export const Disclaimer = () => {
  const { t } = useTranslation(["data"]);
  const {
    query: { indicator },
  } = useRouterQuery<HomeQueryParams>();

  const isIndicatorDiagnoses = indicator === Diseases.Diagnoses.toString();
  const isIndicatorPrep = indicator === Diseases.PrEPCoverage.toString();

  const covidAlertText = isIndicatorDiagnoses
    ? t("disclaimer.covid-alert")
    : t("disclaimer.covid-alert-short");

  const { data: showGoalsNote } = useHomePageCaseCounts({
    select: (rawData) =>
      rawData.data.some((strat) => strat.goals.some((goal) => goal.note)),
  });

  return (
    <>
      <Box className={classes.disclaimer} py={6} px="xs">
        <Text>
          *{" "}
          <Text fz="xs" span>
            {covidAlertText}
          </Text>
        </Text>
        {showGoalsNote && (
          <Text>
            <sup>§</sup>{" "}
            <Text fz="xs" span>
              {t("disclaimer.goals-note")}
            </Text>
          </Text>
        )}
      </Box>
      <Box className={classes.disclaimer} py={6} px="xs" mt="xs">
        <Text fz="xs" span>
          {t("disclaimer.2017-age-range")}
        </Text>
      </Box>
      {isIndicatorPrep && (
        <Box className={classes.disclaimer} py={6} px="xs" mt="xs">
          <Text fz="xs" span>
            {t("disclaimer.prep-note")}{" "}
            <Anchor
              inherit
              href="https://www.cdc.gov/hiv-data/nhss/national-hiv-prevention-and-care-outcomes.html"
              target="_blank"
              c="inherit"
            >
              {t("disclaimer.prep-link")}
            </Anchor>
            .
          </Text>
        </Box>
      )}
    </>
  );
};
